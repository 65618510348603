import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";

const Contact = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(8);

  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = data.slice(indexOfFirstContact, indexOfLastContact);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "contact"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="w-full">
      <h2 className="text-xxl mb-4 fw-bold">Contact</h2>
      <div className="overflow-x-auto">
        <table className="responsive-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Company Name</th>
              <th>Country</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {currentContacts.map((contact) => (
              <tr key={contact.id}>
                <td className="table-nameContact" title="Name">{contact.name}</td>
                <td className="table-nameContact" title="Email">{contact.email}</td>
                <td className="table-nameContact" title="Phone Number">{contact.phone}</td>
                <td className="table-nameContact" title="Company Name">{contact.company}</td>
                <td className="table-nameContact" title="Country">{contact.country}</td>
                <td className="table-comments-contact" title="Comments">{contact.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-wrapper">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage * contactsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
};

export default Contact;
