import { Plus, Pencil, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import AddBlog from "./AddBlog";
import EditBlog from "./EditBlog";
import DeleteBlog from "./DeleteBlog";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

const Blog = () => {
  const [addBlog, setAddBlog] = useState(false);
  const [editBlogId, setEditBlogId] = useState(null);
  const [deleteBlogId, setDeleteBlogId] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(9);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = data.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const fetchData = async () => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const onAddBlog = () => {
    fetchData();
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "blogs", id));
      setData(data.filter((blog) => blog.id !== id));
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };
  const removeHtmlTags = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    return tempElement.innerText;
  };

  const onUpdateBlog = (updatedBlog) => {
    const updatedData = data.map((blog) => {
      if (blog.id === updatedBlog.id) {
        return { ...blog, ...updatedBlog };
      }
      return blog;
    });
    setData(updatedData);
  };
  return (
    <>
      {addBlog ? (
        <AddBlog onClose={() => setAddBlog(false)} onAddBlog={onAddBlog} />
      ) : editBlogId ? (
        <EditBlog
          blog={data.find((blog) => blog.id === editBlogId)}
          onClose={() => setEditBlogId(false)}
          onUpdateBlog={onUpdateBlog}
        />
      ) : (
        <section className="w-full">
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h2 className="text-xxl  fw-bold">Blogs</h2>
            <button
              onClick={() => setAddBlog(!addBlog)}
              className="btn btn-dark"
            >
              <Plus className="mr-1" size={20} /> Add
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentBlogs.map((blog) => (
                  <tr key={blog.id}>
                    <td className="table-title" title="Title">{blog.title}</td>
                    <td title="Image">
                      <a href={blog.image}>
                        <img
                          className="table-image"
                          src={blog.image}
                          alt={blog.title}
                        />{" "}
                      </a>
                    </td>
                    <td className="table-description" title="Description">
                      {removeHtmlTags(blog.description)}
                    </td>
                    <td title="Action">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Pencil
                          className="mr-1 "
                          role="button"
                          size={18}
                          onClick={() => setEditBlogId(blog.id)}
                        />
                        <Trash2
                          className="mr-1 "
                          role="button"
                          size={18}
                          onClick={() => setDeleteBlogId(blog.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-wrapper">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={indexOfLastBlog >= data.length}
              >
                Next
              </button>
            </div>
          </div>
        </section>
      )}

      {deleteBlogId && (
        <DeleteBlog
          id={deleteBlogId}
          onClose={() => setDeleteBlogId(null)}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default Blog;
