import React from 'react'
import {Trash2} from 'lucide-react'
import { deleteDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
const DeleteMedia = ({id, onClose, onDelete }) => {
  
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "media", id));
      onDelete(id); 
      onClose();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };
  return (
    <div class="popup-overlay" id="popup-overlay">
    <div class="popup" id="popup">
      <div class="popup-content">
        <div class="icon">
          <Trash2 />
        </div>
        <h5>Are you sure you want to delete Media / News?</h5>
        <div class="btn-group">
          <button class="btn delete-btn"  onClick={handleDelete}>Delete</button>
          <button class="btn cancel-btn" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DeleteMedia 