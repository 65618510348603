import { Plus, Pencil, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import AddMedia from "./AddMedia";
import EditMedia from "./EditMedia";
import DeleteMedia from "./DeleteMedia";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

const MediaAndNews = () => {
  const [addMedia, setaddMedia] = useState(false);
  const [editMedia, setEditMedia] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [mediaPerPage] = useState(9);

  const [data, setData] = useState([]);

  const indexOfLastMedia = currentPage * mediaPerPage;
  const indexOfFirstMedia = indexOfLastMedia - mediaPerPage;
  const currentMedia = data.slice(indexOfFirstMedia, indexOfLastMedia);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "media"));
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "media", id));
      setData(data.filter((blog) => blog.id !== id));
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const removeHtmlTags = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    return tempElement.innerText;
  };
  const onAddMedia = (newMedia) => {
    setData((prevData) => [...prevData, newMedia]);
  };

  const onUpdateMedia = (updatedMedia) => {
    const updatedData = data.map((media) => {
      if (media.id === updatedMedia.id) {
        return { ...media, ...updatedMedia };
      }
      return media;
    });
    setData(updatedData);
  };
  return (
    <>
      {addMedia ? (
        <AddMedia onClose={() => setaddMedia(false)} onAddMedia={onAddMedia} />
      ) : editMedia ? (
        <EditMedia
          media={data.find((media) => media.id === editMedia)}
          onClose={() => setEditMedia(false)}
          onUpdateMedia={onUpdateMedia}
        />
      ) : (
        <section className="w-full">
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h2 className="text-xxl fw-bold">Media / News</h2>
            <button
              onClick={() => setaddMedia(!addMedia)}
              className="btn btn-dark"
            >
              <Plus className="mr-1" size={20} /> Add
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Image</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentMedia.map((media) => (
                  <tr key={media.id}>
                    <td className="table-title" title="Title">{media.title}</td>
                    <td title="Image">
                      <a href={media.image}>
                        <img
                          className="table-image"
                          src={media.image}
                          alt={media.title}
                        />{" "}
                      </a>
                    </td>
                    <td className="table-description" title="Description">
                      {removeHtmlTags(media.description)}
                    </td>

                    <td title="Action">
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Pencil
                          className="mr-1 "
                          role="button"
                          size={18}
                          onClick={() => setEditMedia(media.id)}
                        />
                        <Trash2
                          className="mr-1 "
                          role="button"
                          size={18}
                          onClick={() => setDeleteMedia(media.id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-wrapper">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Prev
              </button>
              <button
                onClick={handleNextPage}
                disabled={indexOfLastMedia >= data.length}
              >
                Next
              </button>
            </div>
          </div>
        </section>
      )}

      {deleteMedia && (
        <DeleteMedia
          id={deleteMedia}
          onClose={() => setDeleteMedia(null)}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default MediaAndNews;
