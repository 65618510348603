import React from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import Logo from "./Logo";
import { ReactComponent as LogoWhite } from "../assets/images/logos/adminprowhite.svg";
import user1 from "../assets/images/users/user4.jpg";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.js";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const userSignout = () => {
    signOut(auth)
      .then(() => {
        sessionStorage.removeItem("user");
        console.log("signout");
        window.location.href = "/login";
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Navbar light expand="md" className="fix-header bg-emrald  ">
      <div className="d-flex align-items-center">
        <div className=" me-5 pe-3 w-50">
          <Logo />
        </div>
        <Button
          color="primary"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="transparent">
          <img
            src={user1}
            alt="profile"
            className="rounded-circle"
            width="30"
          ></img>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={userSignout}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Navbar>
  );
};

export default Header;
