import AuthDetails from "./views/authDetails";

const App = () => {
  return (
      <div className="dark">
        <AuthDetails />
      </div>
  );
};

export default App;