import React, { useState } from "react";
import { ArrowLeft } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import PopupMessage from "../../components/PopupMessage";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Loader from "../../layouts/loader/Loader";

const EditMedia = ({ onClose, media, onUpdateMedia }) => {
  const [title, setTitle] = useState(media?.title || "");
  const [description, setDescription] = useState(media?.description || "");
  const [image, setImage] = useState(media?.image || "");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  console.log(media);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    let newImageUrl = image;
    if (image instanceof File) {
      setIsUploading(true);
      try {
        const imageName = new Date().getTime() + "_" + image.name;
        const storageRef = ref(storage, `media/${imageName}`);
        const uploadTaskSnapshot = await uploadBytesResumable(
          storageRef,
          image
        );
        newImageUrl = await getDownloadURL(uploadTaskSnapshot.ref);
      } catch (error) {
        console.error("Error uploading new image: ", error);
        setErrorMessage(true);
        setIsUploading(false);
        return;
      }
    }

    try {
      const updatedFields = {
        title: title,
        description: description,
        image: newImageUrl,
      };
      await updateDoc(doc(db, "media", media.id), updatedFields);
      setSuccessMessage(true);
      onUpdateMedia({ ...media, ...updatedFields });
      setTimeout(() => {
        setSuccessMessage(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error updating document: ", error);
      setErrorMessage(true);
    } finally {
      setIsUploading(false);
    }
  };
  const handleReset = () => {
    setTitle(media?.title || "");
    setDescription(media?.description || "");
    setImage(media?.image || "");
    setErrors({});
    setErrorMessage(false);
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!title.trim()) {
      errors.title = "Title is required";
      valid = false;
    }

    if (!description) {
      errors.description = "Description is required";
      valid = false;
    }

    if (!image) {
      errors.image = "Image is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  return (
    <>
      {" "}
      {successMessage && <PopupMessage text="Media Updated successfully" />}
      <section className="w-full">
        <div className="mb-5 d-flex justify-content-between align-items-center">
          <h2 className="text-xxl  fw-bold">Edit Media / News</h2>
          <button className="btn btn-dark" onClick={onClose}>
            <ArrowLeft className="mr-1" size={20} /> Back
          </button>
        </div>
        {isUploading ? (
          <>
            {" "}
            <Loader text="Image uploading..." classname="mt-3" />{" "}
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className=" d-flex flex-wrap justify-content-between align-items-center ">
              <div Style="width:100%">
                <label class="form-label">Title</label>
                <input
                  type="text"
                  class="form-control w-full"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
                  }}
                />
                {errors.title && (
                  <p className="text-red-500 fs-6">{errors.title}</p>
                )}
              </div>
              <div Style="width:100%; margin-top:20px">
                <label class="form-label">Description</label>
                <ReactQuill
                  theme="snow"
                  style={{ height: "300px" }}
                  value={description}
                  onChange={(value) => {
                    setDescription(value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      description: "",
                    }));
                  }}
                />
                {errors.description && (
                  <p className="text-red-500 fs-6">{errors.description}</p>
                )}
              </div>
              <div Style="width:100%; margin-top:60px">
                <label class="form-label">Image</label>
                <input
                  type="file"
                  class="form-control w-full"
                  placeholder="Title"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
                  }}
                />
                {errors.image && (
                  <p className="text-red-500 fs-6">{errors.image}</p>
                )}
              </div>
            </div>
            {errorMessage && (
              <p className="text-red-500 fs-6 text-center mt-2">
                Failed to update Media
              </p>
            )}
            <div className=" d-flex flex-wrap justify-content-center gap-2 align-items-center mt-5">
              <button
                type="submit"
                class="btn btn-primary"
                disabled={isUploading}
              >
                Update
              </button>
              <button
                type="Button"
                class="btn btn-secondary"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default EditMedia;
