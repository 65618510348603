import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

const Loader = ({ text, classname }) => (
  <div className="fallback-spinner">
    <div className={` ${classname} loading`}>
      <Spinner color="primary" />
    </div>
    {text && <p className="text-center text-base mb-2 w-full">{text}</p>}
  </div>
);
export default Loader;
