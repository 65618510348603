import FullLayout from "../layouts/FullLayout.js";
import Contact from "../views/Contact.js";
import Blog from "../views/blog/Blog.js";
import Seo from "../views/Seo.js";
import Media from "../views/media/MediaAndNews.js";
import Login from "../views/Login.js";
import { Navigate } from "react-router-dom";

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/contact" /> },
      { path: "/contact", element: <Contact /> },
      { path: "/blog", element: <Blog /> },
      { path: "/seo", element: <Seo /> },
      { path: "/media-news", element: <Media /> },
    ],
  },
  { path: "/login", element: <Login /> },
];

export default ThemeRoutes;