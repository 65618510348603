import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8m5RSbc-vw_vM_tCItSI_nuy3pCH5Dko",
  authDomain: "telluspowergreen-1a45e.firebaseapp.com",
  projectId: "telluspowergreen-1a45e",
  storageBucket: "telluspowergreen-1a45e.appspot.com",
  messagingSenderId: "672986788452",
  appId: "1:672986788452:web:45924528ee80377cefa571",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);  
export const storage = getStorage(app);
export const auth = getAuth();

