import React, { useState } from "react";
import Logo from "../assets/images/logos/Telluspowergreen.png";
import { auth } from "../firebase.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Eye, EyeOff } from "lucide-react";
import Loader from "../layouts/loader/Loader.js";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const signIn = (e) => {
    e.preventDefault();
    if (validateForm()) {
      signInWithEmailAndPassword(auth, email, password);
      setLoading(true);
      setTimeout(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            console.log(userCredential);
            sessionStorage.setItem("user", JSON.stringify(userCredential.user));
            window.location.href = "/contact";
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 2000);
    }
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
      valid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full d-flex justify-content-center align-items-center container h-screen p-5">
          <div className="login-container">
            <img
              src={Logo}
              alt="Tell us power green "
              title="Tell us power green"
              className="img-fluid mb-3 "
            />
            <h6 className="text-center fw-bold mb-3"> Login</h6>
            <form onSubmit={signIn}>
              <div className="w-100">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control w-full"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value);  setErrors((prevErrors) => ({ ...prevErrors, email: "" })); }}
                />
                {errors.email && (
                  <p className="text-red-500 fs-6">{errors.email}</p>
                )}
              </div>
              <div  className="w-100 mt-3 mb-3">
                <label className="form-label">Password</label>
                <div className="position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control w-full"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {setPassword(e.target.value);  setErrors((prevErrors) => ({ ...prevErrors, password: "" })); }}
                  />
                
                  <div className="position-absolute top-50  translate-middle-y end-5">
                    {showPassword ? (
                      <Eye
                        size={20}
                        role="button"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <EyeOff
                        size={20}
                        role="button"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
                {
                    errors.password && (
                      <p className="text-red-500 fs-6">{errors.password}</p>
                    )
                  }
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
