import React from 'react'

const PopupMessage = ({text}) => {
  return (
    <section className='popup-message'>
        {text}
    </section>
  )
}

export default PopupMessage 