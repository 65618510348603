import React, { useEffect, useState } from "react";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "./../firebase";
import PopupMessage from "../components/PopupMessage";

const Seo = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [errors, setErrors] = useState({});
  const [existingData, setExistingData] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  
  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "seo"));
        if (!querySnapshot.empty) {
          setExistingData(true);
          const data = querySnapshot.docs[0].data();
          setTitle(data.title);
          setDescription(data.description);
          setTags(data.tags);
        } else {
          setExistingData(false);
        }
      } catch (error) {
        console.error("Error fetching SEO data: ", error);
      }
    };
    fetchSeoData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        if (existingData) {
          const docId = (await getDocs(collection(db, "seo"))).docs[0].id;
          await updateDoc(doc(db, "seo", docId), {
            title: title,
            description: description,
            tags: tags,
          });
          setUpdateMessage("SEO updated successfully");
        } else {
          await addDoc(collection(db, "seo"), {
            title: title,
            description: description,
            tags: tags,
          });
          setSuccessMessage("SEO added successfully");
        }
        onHandleReset();
        setTimeout(() => {
          setSuccessMessage("");
          setUpdateMessage("");
        }, 3000); 
      } catch (error) {
        console.error("Error updating/adding SEO: ", error);
        console.log("Failed to update/add SEO");
      }
    }
  };;

  const onHandleReset = () => {
    setTitle("");
    setDescription("");
    setTags("");
    setErrors({});
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!title.trim()) {
      errors.title = "Meta title is required";
      valid = false;
    }

    if (!description) {
      errors.description = " Meta description is required";
      valid = false;
    }
    if (!tags) {
      errors.tags = "Meta tags is required";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  return (
<>
{successMessage && <PopupMessage text={successMessage} />}
      {updateMessage && <PopupMessage text={updateMessage} />}
    <section className="w-full">
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h2 className="text-xxl  fw-bold">Seo</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className=" d-flex flex-wrap justify-content-between align-items-center ">
          <div className="w-100">
            <label className="form-label">Meta Title</label>
            <input
              type="text"
              className="form-control w-full"
              placeholder="Meta Title"
              onChange={(e) => {
                setTitle(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
              }}
              value={title}
            />
            {errors.title && (
              <p className="text-red-500 fs-6">{errors.title}</p>
            )}
          </div>
          <div className="w-100 mt-3">
            <label className="form-label">Meta Description</label>
            <textarea
              type="text"
              rows={5}
              className="form-control w-full"
              placeholder="Meta Description"
              onChange={(e) => {
                setDescription(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, description: "" }));
              }}
              value={description}
            />
            {errors.description && (
              <p className="text-red-500 fs-6">{errors.description}</p>
            )}
          </div>
          <div className="w-100 mt-3">
            <label className="form-label">Meta Tags</label>
            <input
              type="text"
              className="form-control w-full"
              placeholder="Meta Tags"
              onChange={(e) => {
                setTags(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, tags: "" }));
              }}
              value={tags}
            />
            {errors.tags && <p className="text-red-500 fs-6">{errors.tags}</p>}
            <p className="fs-6">
              Separate keywords with a comma, space bar, or enter key
            </p>
          </div>
        </div>
        <div className=" d-flex flex-wrap justify-content-center gap-2 align-items-center mt-5">
          <button type="submit" className="btn btn-primary">
          {existingData ? "Update" : "Submit"}
          </button>
          <button
            type="Button"
            className="btn btn-secondary"
            onClick={onHandleReset}
          >
            Reset
          </button>
        </div>
      </form>
    </section>  
    </>
  );
};

export default Seo;
