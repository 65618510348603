import React, { useEffect, useState } from "react";
import {auth} from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import Themeroutes from "../routes/Router.js";
import Login from "./Login.js";
import { useRoutes } from "react-router-dom";

const AuthDetails = () => {
  const [authUser, setAuthUser] = useState(null);
  const routing = useRoutes(Themeroutes);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      console.log("Authentication state changed:", user);
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
    return () => {
      listen();
    };
  }, []);
  return <div>{authUser ? routing : <Login />}</div>;
};
export default AuthDetails;