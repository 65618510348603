import React, { useEffect, useState } from "react";
import { ArrowLeft } from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  timestamp,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import PopupMessage from "../../components/PopupMessage";
import Loader from "../../layouts/loader/Loader";

const AddMedia = ({ onClose ,onAddMedia}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const uploadImage = async () => {
      if (image) {
        setIsUploading(true);
        const imageName = new Date().getTime() + "_" + image.name;
        const storageRef = ref(storage, imageName);
        const uploadTask = uploadBytesResumable(storageRef, image);
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
          },
          (error) => {
            console.error(error);
            setErrorMessage("Failed to upload image");
            setIsUploading(false); 
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setImageUrl(downloadURL);
              setIsUploading(false); 
            });
          }
        );
      }
    };
    uploadImage();
  }, [image]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isUploading) {
        return;
      }
      try {
        const docRef = await addDoc(collection(db, "media"), {
          title: title,
          description: description,
          image: imageUrl,
          timestamp: serverTimestamp(),
        });
        setSuccessMessage(true);
        const newMedia = {
          id: docRef.id,
          title,
          description,
          image: imageUrl
        };
        onAddMedia(newMedia); 
        setTimeout(() => {
          setSuccessMessage(false);
          onHandleReset();
          onClose();
        }, 3000);
      } catch (error) {
        console.error("Error adding document: ", error);
        setErrorMessage(true);
      }
    }
  };

  const onHandleReset = () => {
    setTitle("");
    setDescription("");
    setImage(null);
    setImageUrl(null);
    setErrors({});
    setErrorMessage(false);
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!title.trim()) {
      errors.title = "Title is required";
      valid = false;
    }

    if (!description) {
      errors.description = "Description is required";
      valid = false;
    }

    if (!image) {
      errors.image = "Image is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };
  return (
    <>
      {successMessage && <PopupMessage text="Media added successfully" />}
    <section className="w-full">
      <div className="mb-5 d-flex justify-content-between align-items-center">
        <h2 className="text-xxl  fw-bold">Add Media / News</h2>
        <button className="btn btn-dark" onClick={onClose}>
          <ArrowLeft className="mr-1" size={20} /> Back
        </button>
      </div>
      {isUploading ? <> <Loader text="Image uploading..." classname="mt-3"/> </> :
      <form onSubmit={handleSubmit}>
        <div className=" d-flex flex-wrap justify-content-between align-items-center ">
          <div Style="width:100%">
            <label class="form-label">Title</label>
            <input
              type="text"
              class="form-control w-full"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
                setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
              }}
              value={title}
            />
            {errors.title && (
              <p className="text-red-500 fs-6">{errors.title}</p>
            )}
          </div>
          <div Style="width:100%; margin-top:20px">
            <label class="form-label">Description</label>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={(value) => {
                setDescription(value);
                setErrors((prevErrors) => ({ ...prevErrors, description: "" }));
              }}
              style={{ height: "300px" }}
            />
          </div>
          <div Style="width:100%; margin-top:60px">
            <label class="form-label">Image</label>
            <input
              type="file"
              class="form-control w-full"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
              }}
              placeholder="Title"
            />
          </div>
        </div>
        {errorMessage && (
            <p className="text-red-500 fs-6 text-center mt-2">Failed to add Media</p>
          )}
        <div className=" d-flex flex-wrap justify-content-center gap-2 align-items-center mt-5">
          <button type="submit" class="btn btn-primary">
            Submit
          </button>
          <button
            type="Button"
            class="btn btn-secondary"
            onClick={onHandleReset}
          >
            Reset
          </button>
        </div>
      </form>
      }
    </section>
    </>
  );
};
//hello
export default AddMedia;
